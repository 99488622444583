import React from "react";
import TableHeader from "./TableHeader";
import TableData from "./TableData";
import useTable from "./useTable";
import "./_table.scss";

export default ({ headers, data, onRowClick, rowId, gridRef }) => {
  const { headerMeta, tableData } = useTable(headers, data);

  return (
    <div className="tableWrapper normalTbl" ref={gridRef ? gridRef : null}>
      <table className="table table-bordered table-condensed table-sm table-striped">
        <TableHeader headers={headerMeta} />
        <TableData
          data={tableData}
          meta={headers}
          onRowClick={onRowClick}
          rowId={rowId}
        />
      </table>
    </div>
  );
};
