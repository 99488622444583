import { useState, useCallback } from "react";

const useForm = (stateSchema = {}, validationSchema = {}, callback) => {
  const [state, setState] = useState(stateSchema);
  const [errors, setErrors] = useState({});

  const validateState = useCallback(() => {
    let isValid = true,
      errors = {};
    Object.keys(validationSchema).forEach((key) => {
      const schema = validationSchema[key];
      if (schema) {
        const { required, validator } = schema;
        const value = state[key];
        if (required && !value) {
          isValid = false;
          errors[key] = "This is required field.";
        }

        if (validator != null && value && !validator.regEx.test(value)) {
          isValid = false;
          errors[key] = validator.error;
        }
      }
    });

    setErrors(errors);

    return isValid;
  }, [state, validationSchema]);

  const handleChange = useCallback((name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleStateChange = (state) => {
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  const handleSubmit = useCallback(
    (event, payload) => {
      event.preventDefault();

      if (validateState()) {
        callback(state, payload);
      }
    },
    [state]
  );

  return {
    state,
    errors,
    setErrors,
    handleChange,
    handleSubmit,
    handleStateChange,
    setState,
  };
};

export default useForm;
