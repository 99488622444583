import { useState } from "react";

const useGrid = (rowId) => {
  const [gridData, setGridData] = useState([]);

  const handleChange = (id, name, value) => {
    const index = gridData.findIndex((c) => c[rowId] === id);
    if (index >= 0) {
      let data = gridData.slice();
      data[index] = { ...data[index], [name]: value };
      setGridData(data);
    }
  };

  const handleRowClick = (id) => {
    const index = gridData.findIndex((c) => c[rowId] === id);
    if (index >= 0) {
      setGridData(
        gridData.map((c) => ({
          ...c,
          selected: c[rowId] === id ? !c.selected : c.selected,
        }))
      );
    }
  };

  const handleRowChange = (id, rowData) => {
    let data = gridData.slice();
    const index = data.findIndex((c) => c[rowId] == id);
    if (index >= 0) data[index] = { ...data[index], ...rowData };
    else data.push(rowData);

    setGridData(data);
  };

  return {
    gridData,
    handleChange,
    handleRowClick,
    setGridData,
    handleRowChange,
  };
};

export default useGrid;
