import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Common } from "../actionTypes/common";
import useToast from "../hooks/useToast";

export default function APIError() {
  const dispatch = useDispatch();
  const { success, message } = useSelector((state) => state.response);
  const { showError, showSuccess } = useToast(onClose);

  const showResponse = () => {
    if (message) {
      if (success) showSuccess(message);
      else showError(message);
    }
  };

  useEffect(showResponse, [message]);

  function onClose() {
    dispatch({ type: Common.SET_API_RESPONSE, result: { message: null } });
  }

  return <></>;
}
