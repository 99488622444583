import React from "react";

export default ({ error }) => {
  return (
    <>
      {error && (
        <div className="textDanger fieldValidationError">
          <span className="">{error}</span>
        </div>
      )}
    </>
  );
};
