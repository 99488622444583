import React, { useEffect, useState } from "react";

function normalizeData(data) {
  return data.map((td) => {
    const keys = Object.keys(td);
    return keys.map((key) => ({ key, text: td[key] }));
  });
}

const compare = {
  ">": (d1, d2) => d1 > d2,
  "<": (d1, d2) => d1 < d2,
};

const useTable = (headers, data) => {
  const [headerMeta, setHeaderMeta] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [sortBy, setSortBy] = useState({ key: null, order: ">" });
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (headers) setHeaderMeta(headers);
  }, [headers]);

  useEffect(() => {
    function sortFunc(m) {
      setSortBy({ key: m.key, order: sortBy.order === ">" ? "<" : ">" });
    }

    setHeaderMeta((currentHeaderMeta) =>
      currentHeaderMeta.map((m) =>
        m.sort ? { ...m, sortFunc: () => sortFunc(m) } : m
      )
    );
  }, [sortBy]);

  useEffect(() => {
    if (data) setTableData(normalizeData(data), headers);
  }, [data]);

  useEffect(() => {
    if (data)
      setTableData(
        normalizeData(
          data.sort((d1, d2) =>
            compare[sortBy.order](d1[sortBy.key], d2[sortBy.key])
          )
        )
      );
  }, [data, sortBy]);

  return {
    headerMeta,
    tableData,
  };
};

export default useTable;
