import React from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

export default ({ options, name, value, onChange, className }) => {
  return (
    <>
      <ButtonGroup toggle className={className}>
        {options &&
          options.map((radio, idx) => (
            <ToggleButton
              key={idx}
              type="radio"
              variant="secondary"
              name="radio"
              value={radio.value}
              checked={value === radio.value}
              size="sm"
              onChange={(e) => onChange(name, e.currentTarget.value)}
            >
              {radio.text}
            </ToggleButton>
          ))}
      </ButtonGroup>
    </>
  );
};
