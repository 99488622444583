import { toast } from "react-toastify";

const useToast = (closeHandler) => {
  const showSuccess = (message) => {
    toast.success(message, {
      onClose: onClose(),
      className: "toastSuccessContainer",
    });
  };

  const showError = (message) => {
    toast.error(message, {
      onClose: onClose(),
    });
  };

  function onClose() {
    if (closeHandler) closeHandler();
  }

  return {
    showSuccess,
    showError,
  };
};

export default useToast;
